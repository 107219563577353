import React from 'react'
import { css } from '@emotion/core'
import { Link } from 'gatsby'
import { color, font, mediaQuery, smartPhone } from '../constants/styles'

const container = css`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  ${mediaQuery[smartPhone]} {
    display: none;
  }
`

const list = css`
  font-size: ${font.textSize};
  display: grid;
  grid-template-columns: 1fr 1fr;
  list-style: none;
  padding: 0px 0 0 24px;
`

const linkItem = css`
  padding-top: 16px;
`

const title = css`
  color: ${color.white};
  font-size: ${font.textsize2};
  width: 70%;
  padding: 0 10px 10px 0;
  font-family: ${font.TitleFontFamily};
`

const link = css`
  color: ${color.white};
  font-family: ${font.TitleFontFamily};
  :hover {
    color: ${color.pink};
  }
`

export const CaseStudies = () => {
  return (
    <section css={container}>
      <h3 css={title}> 開業支援CASE STUDY </h3>
      <ul css={list}>
        <li css={linkItem}>
          <Link css={link} to={`/CaseStudies/CS_Internal`}>
            内科
          </Link>
        </li>
        <li css={linkItem}>
          <Link css={link} to={`/CaseStudies/CS_Orthopedics`}>
            整形外科
          </Link>
        </li>
        <li css={linkItem}>
          <Link css={link} to={`/CaseStudies/CS_Neurosurgery`}>
            脳神経外科
          </Link>
        </li>
        <li css={linkItem}>
          <Link css={link} to={`/CaseStudies/CS_Otolaryngology`}>
            耳鼻咽喉科
          </Link>
        </li>
        <li css={linkItem}>
          <Link css={link} to={`/CaseStudies/CS_Pediatrics`}>
            小児科
          </Link>
        </li>
        <li css={linkItem}>
          <Link css={link} to={`/CaseStudies/CS_Gynecology`}>
            婦人科・乳腺外科
          </Link>
        </li>
        <li css={linkItem}>
          <Link css={link} to={`/CaseStudies/CS_Urology`}>
            泌尿器科
          </Link>
        </li>
        <li css={linkItem}>
          <Link css={link} to={`/CaseStudies/CS_Dermatology`}>
            皮膚科・形成外科
          </Link>
        </li>
        <li css={linkItem}>
          <Link css={link} to={`/CaseStudies/CS_Psychosomatic`}>
            心療内科
          </Link>
        </li>
        <li css={linkItem}>
          <Link css={link} to={`/CaseStudies/CS_Ocular`}>
            眼科
          </Link>
        </li>
      </ul>
    </section>
  )
}
