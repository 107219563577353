import React from "react";
import { css } from "@emotion/core";
import { slide as Menu } from "react-burger-menu";
import { Link } from "gatsby";
import { font, color, mediaQuery, smartPhone } from "../constants/styles";

const sideMenu = css`
  /* menu>itemlist>itemの順にwrapしてる */
  .bm-menu {
    font-size: ${font.subtitleSize};
    z-index: 100;
    ${mediaQuery[smartPhone]} {
      font-size: 14px;
    }
  }
  .bm-item-list {
    color: ${color.white};
    font-family: 'Noto Serif JP', serif;
    font-size: 14;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    }
  .bm-item:not(:last-of-type) {
    width: 100%;
    text-align: center;
    color: ${color.white};
    background: ${color.mainL};
    font-family: 'Noto Serif JP', serif;
    font-size: 14;
    padding: 18px 0;
    line-height: 2;
    border-bottom: 1px solid ${color.gray};
  }
  .bm-item:not(:last-of-type) :hover {
    background: ${color.white};
  }
  .bm-item:last-of-type {
    width: 100%;
    text-align: center;
    color: ${color.white};
    font-family: 'Noto Serif JP', serif;
    font-size: 14;
    padding: 18px 0;
    line-height: 2;
    background: ${color.mainD};
  }
  .bm-item:last-of-type :hover {
    background: ${color.black};
  }
  /* 下記3種は付属品。不要なので非表示 */
  .bm-overlay {
    display: none;
  }
  .bm-cross-button {
    display: none;
  }
  #react-burger-menu-btn {
    display: none;
  }
  .bm-cross {
    display: none;
  }
  @media (min-width: 768px) {
    display: none;
  }
`;

const checkCurrentPage = css`
  pointer-events: none;
  background: ${color.mainD};
`;

export const HeaderMenuSP = (props) => {
  const url = props.location;
  return (
    <header>
      <div css={sideMenu}>
        <Menu right width={"100%"} isOpen={props.menuState}>
          <Link
            css={url === "/" ? checkCurrentPage : null}
            to={`/`}
            onClick={() => props.openCloseBtn()}
          >
            HOME
          </Link>
          <Link
            css={url === "/UnionOverview/" ? checkCurrentPage : null}
            to={`/UnionOverview/`}
            onClick={() => props.openCloseBtn()}
          >
            組合案内
          </Link>
          <Link
            css={url === "/OpeningSupport/" ? checkCurrentPage : null}
            to={`/OpeningSupport/`}
            onClick={() => props.openCloseBtn()}
          >
            開業支援
          </Link>
          <Link
            css={url === "/OpeningList/" ? checkCurrentPage : null}
            to={`/OpeningList/`}
            onClick={() => props.openCloseBtn()}
          >
            開業物件検索
          </Link>
          <Link
            css={url === "/ThirdPartySuccession/" ? checkCurrentPage : null}
            to={`/ThirdPartySuccession/`}
            onClick={() => props.openCloseBtn()}
          >
            第三者継承
          </Link>
          <Link
            css={url === "/QuestionAndAnswers/" ? checkCurrentPage : null}
            to={`/QuestionAndAnswers/`}
            onClick={() => props.openCloseBtn()}
          >
            Q&amp;A
          </Link>
          <Link
            css={url === "/Contact/" ? checkCurrentPage : null}
            to={`/Contact/`}
            onClick={() => props.openCloseBtn()}
          >
            お問い合わせ
          </Link>
        </Menu>
      </div>
    </header>
  );
};
