import React from 'react'
import { css } from '@emotion/core'
import { Link } from 'gatsby'
import { color, mediaQuery, font, smartPhone } from '../constants/styles'

const container = css`
  color: ${color.white};
  display: flex;
  font-size: ${font.textsize2};
  flex-direction: column;
  flex-wrap: wrap;
  ${mediaQuery[smartPhone]} {
    display: none;
  }
`

const paddingTop = css`
  padding-top: 16px;
`

const title = css`
  color: ${color.white};
  font-size: ${font.textsize2};
  width: 100%;
  padding: 0 10px 10px 0;
  font-family: ${font.TitleFontFamily};
`

const link = css`
  color: ${color.white};
  font-family: ${font.TitleFontFamily};
  font-size: ${font.textSize};
  :hover {
    color: ${color.pink};
  }
`

export const PagesLinkItems = () => {
  return (
    <div css={container}>
      <h1 css={title}> サイトメニュー </h1>
      <div css={paddingTop}>
        <Link css={link} to={`/UnionOverview/`}>
          組合案内
        </Link>
      </div>
      <div css={paddingTop}>
        <Link css={link} to={`/OpeningSupport/`}>
          開業支援
        </Link>
      </div>
      {/* 現在準備中のためコメントアウト */}
      {/* <div css={paddingTop}>
        <Link css={link} to={`/Achievement/`}>
          実績
        </Link>
      </div> */}
      <div css={paddingTop}>
        <Link css={link} to={`/ThirdPartySuccession/`}>
          第三者継承
        </Link>
      </div>
      <div css={paddingTop}>
        <Link css={link} to={`/OpeningList/`}>
          開業物件検索
        </Link>
      </div>
      <div css={paddingTop}>
        <Link css={link} to={`/QuestionAndAnswers/`}>
          Q&amp;A
        </Link>
      </div>
      <div css={paddingTop}>
        <Link css={link} to={`/News/`}>
          お知らせ
        </Link>
      </div>
      <div css={paddingTop}>
        <Link css={link} to={`/Contact/`}>
          お問い合わせ
        </Link>
      </div>
    </div>
  )
}
