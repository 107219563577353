import React from 'react'
import { Global, css } from '@emotion/core'
import { color, mediaQuery, smartPhone } from '../constants/styles'
import { resetCSS } from '../constants/resetCSS'
import { HeaderSP } from './HeaderSP'
import { Header } from './Header'
import { Footer } from './Footer'
import { BottomBar } from './BottomBar'

const wrapper = css`
  max-width: 1480px;
  margin: 0 auto;
`

const baseCSS = css`
  background-color: ${color.white};
`

const mainContainer = css`
  margin-bottom: 10px;
`

export const Layout = ({ children, location }) => {
  return (
    <div css={wrapper}>
      <Global styles={[resetCSS, baseCSS]} />
      <HeaderSP location={location} />
      <Header />
      <main css={mainContainer}>
        {children}
        <Footer />
      </main>
      <BottomBar />
    </div>
  )
}
