import React from "react";
import { css } from "@emotion/core";
import { font, color, mediaQuery, smartPhone } from "../constants/styles";

const container = css`
  background-color: ${color.mainD};
  color: ${color.white};
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${font.ButtonTextSize};
  margin-top: -70px;
  ${mediaQuery[smartPhone]} {
    height: 52px;
    margin-top: -52px;
  }
`;

export const BottomBar = () => {
  return (
    <footer css={container}>
      <small>&copy; Nara Medical Association</small>
    </footer>
  );
};
