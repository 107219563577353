import React from 'react'
import { css } from '@emotion/core'
import { mediaQuery, smartPhone, font, color } from '../constants/styles'
import { Link } from 'gatsby'

const container = css`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  ${mediaQuery[smartPhone]} {
    display: block;
  }
`

const title = css`
  font-family: 'Noto Serif JP', serif;
  color: ${color.white};
  font-size: ${font.subtitleSize};
  font-style: normal;
  font-family: ${font.TitleFontFamily};
  :hover {
    opacity: 0.7;
  }
  ${mediaQuery[smartPhone]} {
    padding-top: 0;
    font-size: 18px;
  }
`

const address = css`
  font-family: 'Noto Serif JP', serif;
  font-size: ${font.textsize2};
  padding-top: 10px;
  font-style: normal;
  ${mediaQuery[smartPhone]} {
    font-size: ${font.ButtonTextSize};
  }
`;

const addressText = css`
  font-family: 'Noto Serif JP', serif;
  color: ${color.white};
  font-size: ${font.textSize};
  font-style: normal;
  padding-top: 4px;
  ${mediaQuery[smartPhone]} {
    font-size: ${font.ButtonTextSize};
  }
`;

const linkContainer = css`
  font-size: ${font.textSize};
  padding-top: 10px;
  ${mediaQuery[smartPhone]} {
    display: none;
  }
`

const paddingTop = css`
  padding-top: 10px;
`

const link = css`
  font-family: ${font.TitleFontFamily};
  color: ${color.white};
  :hover {
    opacity: 0.7;
  }
`

const ggmap = css`
  padding-top: 20px;
  width: 100%;
  height: 50px;
  margin: 0 auto;
`
export const AddressBlock = () => {
  return (
    <section css={container}>
      <Link to={`/`}>
        <h1 css={title}> 奈良県医師協同組合 </h1>
      </Link>
      <div css={paddingTop}>
        <address css={addressText}>
          <p>〒630-8013</p>
          <p css={addressText}>奈良市三条大路1-1-87 奈良市役所前三和ビル2F</p>
          <p css={address}>Tel: 0742-34-7991 Fax: 0742-34-7992</p>
        </address>
      </div>
      <div css={linkContainer}>
        <a href="http://nara-ikyo.com/" css={link}>
          ホームページ（http://nara-ikyo.com/）
        </a>
      </div>
      <div css={ggmap}>
        <div class="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3280.8339147276643!2d135.80150501499665!3d34.68414099161307!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60013bb3fb4785c5%3A0xe4492c7ca0013e7c!2z44CSNjMwLTgwMTMg5aWI6Imv55yM5aWI6Imv5biC5LiJ5p2h5aSn6Lev77yR5LiB55uu77yRIOW4guW9ueaJgOWJjeS4ieWSjOODk-ODqyAyZg!5e0!3m2!1sja!2sjp!4v1630906992782!5m2!1sja!2sjp"
            frameborder="0"
          ></iframe>
        </div>
      </div>
    </section>
  )
}
