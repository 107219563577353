import React from "react";
import favicon from '../images/favicon.ico'
import Helmet from "react-helmet";
import ogpImage from "../images/ogpImage.png";

export const Head = (siteMetadata) => {
  const { title, siteUrl, description } = siteMetadata.props
  return (
    <Helmet
      htmlAttributes={{
        lang: "ja",
      }}
      title={title}
      meta={[
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:image",
          content: `${siteUrl}${ogpImage}`,
        },
        {
          name: "twitter:title",
          content: `${title}`
        },
        {
          name: "twitter:description",
          content: `${description}`,
        },
        {
          name: "description",
          content: `${description}`,
        },
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:title",
          content: `${title}`,
        },
        {
          property: "og:description",
          content: `${description}`,
        },
        {
          property: "og:image",
          content: `${siteUrl}${ogpImage}`,
        }
      ]}
    >
      <link rel="icon" href={favicon} />
    </Helmet>
  );
};
