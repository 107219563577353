import React, { useState } from "react";
import { Link } from "gatsby";
import { css } from "@emotion/core";
import { color } from "../constants/styles";
import style from "../css/style.less";
import { HeaderMenuSP } from "./HeaderMenuSP";

const navBar = css`
  height: 62px;
  width: 100%;
  background: ${color.mainL};
  color: ${color.white};
  position: fixed;
  z-index: 1000;
  @media (min-width: 768px) {
    display: none;
  }
`;

const wrapper = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 90%;
  margin: 0 auto;
`;

const title = css`
  font-family: 'Noto Serif JP', serif;
  font-size: 14;
  line-height: 20px;
  letter-spacing: 0.3em;
  color: ${color.white};
`

const menuBar = css`
  color: ${color.white};
  background-color: ${color.white};
`

export const HeaderSP = ({ location }) => {
  const [menuState, setMenuOpen] = useState(false);
  const openMenu = () => {
    setMenuOpen(true);
  };
  const closeMenu = () => {
    setMenuOpen(false);
  };
  const openCloseBtn = () => {
    if (menuState === false) openMenu();
    if (menuState === true) closeMenu();
  };

  return (
    <div css={navBar}>
      <div css={wrapper}>
        <Link to={`/`}>
          <h1 css={title}>奈良県医師協同組合</h1>
        </Link>
        <div class="drawer">
          <input
            type="checkbox"
            id="drawer-check"
            class="drawerHidden"
            className={style.drawerHidden}
          />
          <label
            for="drawer-check"
            class="drawerOpen"
            className={style.drawerOpen}
            onClick={() => openCloseBtn()}
          >
            <span css={menuBar}></span>
          </label>
          <HeaderMenuSP
            menuState={menuState}
            openCloseBtn={openCloseBtn}
            location={location}
          />
        </div>
      </div>
    </div>
  );
};
