export const color = {
  mainL: "#7F3653",
  mainD: "#5F273D",
  white: "#FFFFFF",
  whiteGray: "#F2F2F2",
  gray: "#E0E0E0",
  black: "#4f4f4f",
  pink: "#F8E1E3",
  darkGray: "#555555",
  darkBlue: "#3B3B55",
};

export const font = {
  NomaltitleSize: "36px",
  BigtitleSize: "48px",
  subtitleSize: "24px",
  textSize: "15px",
  textsize2: "20px",
  ButtonTextSize: "12px",
  TitleFontFamily: "Noto Serif JP, serif",
};

const break_points = [768, 1024];
export const smartPhone = 0;
export const tablet = 1;
export const mediaQuery = break_points.map(
  (bp) => `@media (max-width: ${bp}px)`
);
