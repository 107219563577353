import React from "react";
import { css } from "@emotion/core";
import { font, mediaQuery, smartPhone, color } from "../constants/styles";
import { AddressBlock } from "./AddressBlock";
import { PagesLinkItems } from "./PagesLinkItems";
import { CaseStudies } from "./CaseStudies";
import scrollTo from "gatsby-plugin-smoothscroll";
import scroll from "../images/scroll.svg";

const wrapper = css`
  background-color: ${color.mainL};
  margin: 0 auto;
  ${mediaQuery[smartPhone]} {
    height: 440px;
  }
`;

const container = css`
  width: 80%;
  display: grid;
  grid-template-columns: 3fr 1fr 2fr;
  gap: 16px;
  margin: 0 auto;
  margin-bottom: 80px;

  padding: 80px 0 8px 0;
  ${mediaQuery[smartPhone]} {
    grid-template-columns: 1fr;
    width: 90%;
    text-align: center;
    margin: 0 auto;
  }
`;

const buttonContainer = css`
  display: flex;
  justify-content: flex-end;
  ${mediaQuery[smartPhone]} {
    display: none;
  }
`;

const scrollButton = css`
  width: 60px;
  height: 60px;
  padding: 0px 100px 10px 0px;
  cursor: pointer;
  @keyframes slide-top {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-20px);
    }
  }
  :hover {
    animation: slide-top 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
`;

export const Footer = () => {
  return (
    <div css={wrapper}>
      <div css={container}>
        <AddressBlock />
        <PagesLinkItems />
        <CaseStudies />
      </div>
      <div css={buttonContainer}>
        <img
          alt="スクロールボタン"
          onClick={() => scrollTo("body")}
          css={scrollButton}
          src={scroll}
        />
      </div>
    </div>
  );
};
