import React from 'react'
import { css } from '@emotion/core'
import { Link } from 'gatsby'
import { color, mediaQuery, smartPhone, font } from '../constants/styles'

const header = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${color.mainL};
  color: ${color.white};
  font-size: clamp(10px, (100vw - 1024px) * 0.004 + 100%, 14px);
  height: 80px;
  position: sticky;
  top: 0;
  z-index: 100;
  ${mediaQuery[smartPhone]} {
    display: none;
  }
`

const logoItem = css`
  font-family: ${font.TitleFontFamily};
  color: ${color.white};
  height: 100%;
  display: flex;
  font-size: ${font.textsize2};
  font-weight: Bold;
  flex: 1 1 100px;
  align-items: center;
  justify-content: start;
  padding: 0 4px;
  :first-of-type {
    padding-left: 32px;
  }
`

const headerItem = css`
  font-family: ${font.TitleFontFamily};
  color: ${color.white};
  height: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 0 4px;

  :hover {
    color: ${color.gray};
  }
`

const contact = css`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  background-color: ${color.mainL};
  color: ${color.white};
  margin-left: auto;
`

export const Header = () => {
  return (
    <header css={header}>
      <Link css={logoItem} to={`/`}>
        奈良県医師協同組合
      </Link>
      <Link css={headerItem} to={`/`}>
        HOME
      </Link>
      <Link css={headerItem} to={`/UnionOverview/`}>
        組合案内
      </Link>
      <Link css={headerItem} to={`/OpeningSupport/`}>
        開業支援
      </Link>
      <Link css={headerItem} to={`/OpeningList/`}>
        開業物件検索
      </Link>
      <Link css={headerItem} to={`/ThirdPartySuccession/`}>
        第三者継承
      </Link>
      <Link css={headerItem} to={`/QuestionAndAnswers/`}>
        Q&amp;A
      </Link>
      <Link css={[headerItem, contact]} to={`/Contact/`}>
        お問い合わせ
      </Link>
    </header>
  )
}
